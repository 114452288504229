@import url('https://fonts.googleapis.com/css?family=Open+Sans:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=PT_Serif:regular,italic&subset=latin,latin-ext');

body {
  min-height: 200vh;
}

#chat-ai {
  z-index: 100000000;
  position: relative;
}

@keyframes blink {
  from {
    background: transparent;
  }
  50% {
    background: #000;
  }
}

@keyframes blinkspin {
  from {
    transform:rotate(0deg);
    left:0;
    opacity:1;
  }
  30% {
    transform:rotate(10deg);
    left:10px;
    opacity:0.1;
  }
  50% {
    transform:rotate(0deg);
    left:10px;
    opacity:1;
  }
  80% {
    transform:rotate(-10deg);
    left:0px;
    opacity:0.1;
  }
  100%  {
    transform:rotate(0deg);
    left:0px;
  }
}

@keyframes loader-anim1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loader-anim3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loader-anim2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}